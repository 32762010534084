<template>
    <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item ref="name" label="关键词名称" name="name">
        <a-input v-model:value="form.name" placeholder='请输入关键词名称'  />
      </a-form-item>
      <a-form-item ref="content" label="关键词替换参数" name="content">
        <a-input v-model:value="form.content" placeholder='请输入关键词替换参数'  />
      </a-form-item>
      <a-form-item ref="rule" label="关键词参数类型" name="rule">
        <a-input v-model:value="form.rule" placeholder='请输入关键词参数类型'  />
      </a-form-item>
      <a-form-item ref="example" label="关键词示例" name="example" help='关键词示例为前端预览作用，请注意填写'>
        <a-input v-model:value="form.example" placeholder='请输入关键词示例'  />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 8, offset: 4 }">
        <a-button type="primary" @click="onSubmit" :loading='loading'>
          保存
        </a-button>
      </a-form-item>
    </a-form>
</template>
<script>
  import { ref, onMounted, reactive, toRefs, inject } from 'vue';
  import { message } from 'ant-design-vue';
  import { $iscode } from "@/utils/app";
  import { messProjectAdd, messProjectEdit} from "@/api/brand";
  // 初始化默认筛选项数值
  let defForm = {
    name: '',
    content:'',
    rule: '',
    example:''
  }
  export default {
    components: {},
    setup(props,{emit}) {
      const form = ref({ ...defForm });
      const state = reactive({
        loading:false,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        rules: {
          name: [
            { required: true, message: '请输入关键词名称'}
          ],
          content: [
            { required: true, message: '请输入关键词替换参数'}
          ],
          rule: [
            { required: true, message: '请输入关键词参数类型'}
          ],
          example: [
            { required: true, message: '请输入关键词示例'}
          ]
        }
      });
      const ruleForm = ref()
      const onSubmit = () => {
        ruleForm.value.validate().then(async() => {
          state.loading = true
          emit('onsubmit',form.value)
          state.loading = false
        }).catch(error => {
            console.log('error', error);
        });
      }
      onMounted(() => {
        
      });
      return {
        form,
        ...toRefs(state),
        ruleForm,
        onSubmit
      }
    }
  }
</script>
<style>
</style>